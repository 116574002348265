<template>
  <div>
    <quick-links />
    <b-card class="border-0" no-body>
      <template #header>
        <div class="d-flex">
          <strong class="flex-grow-1">{{ title }}</strong>
          <b-button variant="link" @click="show = !show">
            <b-icon :icon="'chevron-double-' + (show ? 'up' : 'down')" />
          </b-button>
        </div>
      </template>
      <b-collapse v-model="show">
        <div class="row">
          <div class="col"><member-details /></div>
          <div class="col"><training-center-details /></div>
        </div>
      </b-collapse>
      <router-view />
    </b-card>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import MemberDetails from '@/views/details/MemberDetails';
import TrainingCenterDetails from '@/views/details/TrainingCenterDetails';
import QuickLinks from '@/pages/quick_links/views/QuickLinks';

@Component({
  components: {
    MemberDetails,
    TrainingCenterDetails,
    QuickLinks
  }
})
export default class UnionDetails extends Vue {
  show = true;
  get title() {
    return this.$store.getters.title;
  }
}
</script>
<style scoped></style>
